body {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
  }
  footer {
    background-color: #080808;
    color: #a7a7a7;
    font-size: 15px;
  }
  footer * {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    border: none;
    outline: none;
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .row {
    padding: 0.5em 0.5em;
  }
  .row.primary {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    align-items: stretch;
  }

  @media screen and (max-width: 850px) {
    .row.primary {
      grid-template-columns: 1fr;
    }
  }